import { Card, Col, Row } from "antd";
import IMAGES from "../assets/images";

function Contact() {
  return (
    <Row className="h-100 d-flex justify-content-center">
      <Col sm={20} xl={12}>
        <Card className="glassy-card">
          <div className="d-flex align-items-start justify-content-between">
            <div className="fs-xxs fw-normal py-1 text-black">
              Frequently asked questions
            </div>
            <img
              src={IMAGES.CART_CLOSE_ICON}
              alt="cart close icon"
              width="50px"
              className="cross-icon"
            />
          </div>
          <div className="bg-white p-2 mb-2 faq" style={{ minHeight: "50vh" }}>
            <h2 className="fw-normal">
              hi guys!!!1!1
              <br />
              Answers for frequently asked questions:
            </h2>
            <h4 className="fw-normal">
              {/* -This is one man operation, everything is hand printed by me
              <br /> */}
              -All orders guaranteed to ship within 2-5 days of the order date
              <br />
              -All tees are printed on Hanes Beefy-t{" "}
              <span className="text-blue underline">size chart</span>
            </h4>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default Contact;
